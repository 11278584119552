var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("الرئيسية")])])])]}}])}),_c('router-link',{attrs:{"to":"/tasks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("كل المهام")])])])]}}])}),_c('router-link',{attrs:{"to":"/my-task"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("المهام المسندة لأشخاص أخرين")])])])]}}])}),_c('router-link',{attrs:{"to":"/add-task"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("إضافة مهمة")])])])]}}])}),_c('router-link',{attrs:{"to":"/my-visits"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("كل الزيارات")])])])]}}])}),_c('router-link',{attrs:{"to":"/visits"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("الزيارات المسندة لأشخاص أخرين")])])])]}}])}),_c('router-link',{attrs:{"to":"/visit-reports"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("تقارير الزيارات")])])])]}}])}),_c('router-link',{attrs:{"to":"/externals"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("الجهات الخارجية")])])])]}}])}),_c('router-link',{attrs:{"to":"/messages"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("المراسلات")])])])]}}])}),_c('router-link',{attrs:{"to":"/achievements"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("إنجاز المندوب")])])])]}}])}),_c('router-link',{attrs:{"to":"/targets"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("الأهداف الدورية للمندوبين")])])])]}}])}),_c('router-link',{attrs:{"to":"/fill-questionnaire"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text",staticStyle:{"font-size":"14px"}},[_vm._v("ملئ الاستبيان")])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }