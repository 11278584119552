<template>
  <div v-if="role == 'Salesman'">
    <SalesmanMeunu />
  </div>
  <div v-else>
    <ul class="menu-nav">
      <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">الرئيسية</span>
          </a>
        </li>
      </router-link>

      <router-link to="/users" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">المستخدمين</span>
          </a>
        </li>
      </router-link>

      <router-link to="/tasks" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">كل المهام</span>
          </a>
        </li>
      </router-link>

      <router-link to="/my-task" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >المهام المسندة لأشخاص أخرين</span
            >
          </a>
        </li>
      </router-link>

      <router-link to="/add-task" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">إضافة مهمة</span>
          </a>
        </li>
      </router-link>

      <router-link to="/visits" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >الزيارات المسندة لأشخاص أخرين</span
            >
          </a>
        </li>
      </router-link>

      <router-link
        to="/visit-reports"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">تقارير الزيارات</span>
          </a>
        </li>
      </router-link>

      <router-link to="/externals" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">الجهات الخارجية</span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/add-external"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">إضافة جهة خارجية</span>
          </a>
        </li>
      </router-link>
     
    <!-- <router-link
        to="/year-sales"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >المبيعات السنوية حتى اليوم</span
            >
          </a>
        </li>
      </router-link> 
      <router-link
        to="/month-sales"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >المبيعات الشهرية حتى اليوم</span
            >
          </a>
        </li>
      </router-link> -->
   <router-link

        to="/annual-plan"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >الخطة السنوية</span
            >
          </a>
        </li>
      </router-link>
       <router-link

        to="/predictive"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >البيانات التنبؤية</span
            >
          </a>
        </li>
      </router-link>
       
      <router-link
        to="/customer-activity"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">نشاط العميل</span>
          </a>
        </li>
      </router-link>
         <router-link
        to="/customer-profile"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              > تقرير مبيع العميل</span
            >
          </a>
        </li>
      </router-link>
           <router-link
        to="/customer-daily-target"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              > تقرير التتبع اليومي للعميل</span
            >
          </a>
        </li>
      </router-link>
       <router-link
        to="/year-report"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">التقرير السنوي </span>
          </a>
        </li>
      </router-link>
        <router-link
        to="/market-segmentation"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">الحصة السوقية </span>
          </a>
        </li>
      </router-link>
        <router-link
        to="/sales-area"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">المبيع الشهري حسب المنطقة </span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/marketdetails"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">المبيع السنوي حتى اليوم</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/achievements"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">إنجاز المندوب</span>
          </a>
        </li>
      </router-link>

      <router-link to="/targets" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text"
              >الأهداف الدورية للمندوبين</span
            >
          </a>
        </li>
      </router-link>

      <router-link to="/messages" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">المراسلات</span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/questionnaires"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">عرض كل الاستبيانات</span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/add-questionnaire"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers-1"></i>
            <span style="font-size: 14px" class="menu-text">إضافة استبيان</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { GET_USER } from "@/core/services/store/auth.module";
import SalesmanMeunu from "./SalesmanMeunu.vue";
import ApiService from "@/core/services/api.service";
export default {
  name: "KTMenu",

  components: {
    SalesmanMeunu,
  },

  data() {
    return {
      user: null,
      role: "",
    };
  },

  created() {
    this.$store.dispatch(GET_USER).then(() => {
      this.user = JSON.parse(window.localStorage.getItem("authUser"));
    });

    this.getuserid();
  },

  // beforeRouteEnter(to, from, next) {
  //   if (
  //     window.localStorage.getItem("roles") == "" ||
  //     window.localStorage.getItem("roles") == null
  //   ) {
  //     this.$store.dispatch(GET_USER);
  //     next();
  //   }
  //   next();
  // },
  methods: {
    getuserid() {
      this.user = JSON.parse(window.localStorage.getItem("authUser"));
      ApiService.get("Auth/GetUser?id=" + this.user.id).then((data) => {
        this.role = data.data.roles[0];
      });
    },
  }
};
</script>

<style scoped>
span {
  font-size: 20px;
}
</style>