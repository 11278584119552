<template>
  <div class="topbar-item">
    <div class="d-flex align-items-center">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown"
        no-caret
        right
        no-flip
        text="Actions"
        v-b-tooltip.hover="'الإشعارات'"
      >
        <template v-slot:button-content>
          <a href="#" class="btn btn-icon" data-toggle="dropdown">
            <span class="svg-icon svg-icon-success svg-icon-2x">
              <!--begin::Svg Icon-->
              <!--   <inline-svg src="media/svg/icons/Files/File-plus.svg" />-->
              <notification-bell
                iconColor="gray"
                :size="20"
                :upperLimit="9"
                counterLocation="upperLeft"
                :count="totalNotifications"
              />
              
              <!--end::Svg Icon-->
            </span>
          </a>
        </template>
        
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-250px">
          <b-dropdown-text
            tag="div"
            v-if="notifications.length > 0"
            class="navi-item"
          >
            <a
              @click.prevent="readNotification(notification)"
              class="navi-link"
              v-for="notification in notifications"
              :key="notification.idnotification"
            >
              <span class="navi-icon">
                <i class="flaticon2-new-email"></i>
              </span>
              <span class="navi-text">{{ notification.message }}</span>
            </a>

            <hr />
            <a
              class="navi-link"
              @click.prevent="$router.push({ name: 'notifications' })"
            >
              <span>كل الإشعارات</span>
            </a>
            
          </b-dropdown-text>

          <b-dropdown-text tag="div" v-else class="navi-item">
            <a class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-new-email"></i>
              </span>
              <span class="navi-text">لا توجد إشعارات</span>
            </a>
          </b-dropdown-text>
        </div>
        <!--end::Navigation-->
      </b-dropdown>
    </div>
     <div
      class="
        btn btn-icon
        w-auto
        btn-clean
        d-flex
        align-items-center
        btn-lg
        px-2
      "
    >
      <button class="btn btn-light-info btn-bold" @click="onLogout">
              <b style="color: black">تسجيل الخروج</b>
            </button>
    </div>
    <div
      class="
        btn btn-icon
        w-auto
        btn-clean
        d-flex
        align-items-center
        btn-lg
        px-2
      "
      id="kt_quick_user_toggle"
    >
      <span
        class="
          text-dark-50
          font-weight-bolder
          symbol-label
          font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        <!-- <strong>{{user.userName}}</strong> -->
      </span>
      <span class="symbol symbol-35">
        <!--  <img v-if="false" alt="Pic" :src="picture" />-->
        <!--  <span v-if="true" class="symbol-label font-weight-bold" style="font-size:10px">-->
        <small>{{ this.user.userName }}</small>
        <!--  </span>-->
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bold m-0">
          <!--  some info we want add -->
          <!--  <small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <img src="media/imgs/logo.png" />
        <br />
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <!--  <img class="symbol-label" :src="picture" alt="" />-->
            <!--  <i class="symbol-badge bg-success"></i>-->
          </div>
          <div class="d-flex flex-column">
            <router-link to="/my-profile">
              <b style="color: black">{{ user.fullName }}</b>
            </router-link>
            <!--  <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <!--  <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />-->
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <!-- <span class="navi-text text-muted text-hover-primary">
                    jm@softplus.com
                  </span>-->
                </span>
              </a>
            </div>
            <button class="btn btn-light-info btn-bold" @click="onLogout">
              <b style="color: black">تسجيل الخروج</b>
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/my-profile"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold"><b>حسابي</b></div>
                <!--<div class="text-muted">Logs and notifications</div>-->
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link to="/notifications" href="#" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold"><b>كل الإشعارات</b></div>
                <!--  <div class="text-muted">latest tasks and projects</div>-->
              </div>
            </div>
          </router-link>
          <!--end:Item-->

          <!-- </template>-->
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
svg:hover {
  pointer-events: none;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import NotificationBell from "vue-notification-bell";
export default {
  sockets: {
    ReceiveNewNotification() {
      this.getNotifications();
    },
    ReceiveNewMsg() {
      this.getNotifications();
    },
    ReceiveNewStatus() {
      this.getNotifications();
    }
  },

  name: "KTQuickUser",

  components: {
    NotificationBell
  },

  data() {
    return {
      visit: "",
      role: null,
      user: null,
      notifications: [],
      totalNotifications: 0
    };
  },

  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    }
  },

  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");

    //this.getNotifications();
    // this.getNotifications;
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },

  methods: {
    async getNotifications() {
      await this.ApiService.get(
        "Notifications/GetNotificationByUser?user=" + this.user.id
      )
        .then(res => {
          this.totalNotifications = 0;
          this.notifications = [];
          res.data.responseData.forEach(el => {
            if (el.read == 0) {
              this.totalNotifications++;
              if (this.totalNotifications < 6) this.notifications.push(el);
            }
          });
        })
        .catch(() => {});
    },
    async getVisitId(id) {
      await this.ApiService.get("visit/NewVisitsByUser?user=" + this.user.id)
        .then(res => {
          res.data.responseData.forEach(el => {
            if (el.task.idtask == id) {
              this.visit = el;
            }
          });
        })
        .catch(() => {});
    },
    async readNotification(noti) {
      this.totalNotifications--;
      await this.ApiService.put(
        "Notifications/MarkAsRead?notificationID=" + noti.idnotification
      )
        .then(() => {
          if (noti.type == "NewTask") {
            if (noti.task.typeId == 1) {
              this.getVisitId(noti.task.idtask).then(() => {
                this.$router.push({
                  name: "show-visit",
                  params: { visitId: this.visit.idvisit, visit: this.visit }
                });
              });
            } else
              this.$router.push({
                name: "show-task",
                params: { task: noti.task, taskId: noti.task.idtask }
              });
          } else if (noti.type == "NewMsg")
            this.$router.push({
              name: "chatting",
              params: { taskId: noti.task.idtask }
            });
          else
            this.$router.push({
              name: "show-task",
              params: { task: noti.task, taskId: noti.task.idtask }
            });
        })
        .catch(() => {});
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  }
};
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
svg:hover {
  pointer-events: none;
}
</style>