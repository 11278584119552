<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">الرئيسية</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/tasks"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">كل المهام</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/my-task"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text"
            >المهام المسندة لأشخاص أخرين</span
          >
        </a>
      </li>
    </router-link>

    <router-link
      to="/add-task"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">إضافة مهمة</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/my-visits"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">كل الزيارات</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/visits"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text"
            >الزيارات المسندة لأشخاص أخرين</span
          >
        </a>
      </li>
    </router-link>

    <router-link
      to="/visit-reports"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">تقارير الزيارات</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/externals"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">الجهات الخارجية</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/messages"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">المراسلات</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/achievements"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">إنجاز المندوب</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/targets"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text"
            >الأهداف الدورية للمندوبين</span
          >
        </a>
      </li>
    </router-link>

    <router-link
      to="/fill-questionnaire"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span style="font-size:14px;" class="menu-text">ملئ الاستبيان</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "SalemanMenu"
};
</script>
<style scoped>
span {
  font-size: 20px;
}
</style>
